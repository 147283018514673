import { SellerBadgeDto } from 'types/dtos/seller-badge'
import { SellerBadgeModel } from 'types/models/seller-badge'

export const transformSellerBadgeDto = ({
  type,
  progress: { current, goal },
}: SellerBadgeDto): SellerBadgeModel => ({
  type,
  progress: { current, goal },
})
