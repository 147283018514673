import { PaginationModel, transformPaginationDto } from '@marketplace-web/domain/pagination-data'
import { Response } from '@marketplace-web/shared/api-client'

import { GetWardrobeItemsResp } from 'types/api/response/get-wardrobe-items-resp'

import { ProductItemModel } from 'types/models/product-item'

import { transformWardrobeItemDtosToProductItems } from 'data/transformers/wardrobe-item'

export const transformWardrobeItemsResponse = (
  response: Response<GetWardrobeItemsResp>,
): {
  items: Array<ProductItemModel>
  pagination: PaginationModel
} => ({
  items: transformWardrobeItemDtosToProductItems(response.items),
  pagination: transformPaginationDto(response.pagination),
})
