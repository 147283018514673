export const getIsAccountBanPermanent = (accountBanDate: string | null) => {
  if (!accountBanDate) return true

  const banDate = new Date(accountBanDate)

  if (Number.isNaN(banDate.getTime())) return true

  const diffInYears = banDate.getFullYear() - new Date().getFullYear()

  return diffInYears >= 5
}
