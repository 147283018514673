import { useEffect } from 'react'

import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { useFetch } from '@marketplace-web/shared/api-client'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useSession } from '@marketplace-web/shared/session'
import { getSellerBadgesList } from 'data/api'
import { UserDto } from '@marketplace-web/shared/user-data'

type Props = {
  user: UserDto | undefined
}

const useFetchSellerBadgesList = ({ user }: Props) => {
  const { data: sellerBadgesList, fetch: fetchSellerBadgesList } = useFetch(getSellerBadgesList)
  const isFastReplierBadgeEnabled = useFeatureSwitch('web_fast_replier_badge')
  const fastReplierBadgeAbTest = useAbTest('fast_replier_badge_v3')
  const { id: currentUserId, business: isCurrentUserBusiness } = useSession().user || {}
  const { id: userId, business } = user || {}

  const isViewingSelf = currentUserId === userId
  const bothUsersNotBusiness = !isCurrentUserBusiness && !business

  const shouldFetchSellerBadgesList =
    isFastReplierBadgeEnabled &&
    fastReplierBadgeAbTest?.variant === 'on' &&
    !isViewingSelf &&
    bothUsersNotBusiness

  useEffect(() => {
    if (!userId) return

    if (shouldFetchSellerBadgesList) fetchSellerBadgesList(userId)
  }, [fetchSellerBadgesList, isViewingSelf, shouldFetchSellerBadgesList, user, userId])

  return {
    sellerBadgesList: sellerBadgesList?.badges,
  }
}

export default useFetchSellerBadgesList
