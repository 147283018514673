'use client'

import { Spacer } from '@vinted/web-ui'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useBanners } from '@marketplace-web/domain/banners'
import { TaxpayerBanner, TaxpayerBannerRenderLocation } from '@marketplace-web/domain/taxpayers'
import { Screen } from 'constants/tracking/screens'

const ProfileTaxpayerBanner = () => {
  const isTaxpayerSpecialVerificationBannerFsEnabled = useFeatureSwitch(
    'web_special_verification_taxpayers_banners',
  )

  const { banners } = useBanners()

  const { taxpayerBanner } = banners

  if (!taxpayerBanner) return null

  const isTaxpayersSpecialVerificationBanner = taxpayerBanner.isSpecialVerification

  const shouldDisplayBanner =
    !isTaxpayersSpecialVerificationBanner ||
    (isTaxpayersSpecialVerificationBanner && isTaxpayerSpecialVerificationBannerFsEnabled)

  if (!shouldDisplayBanner) return null

  return (
    <>
      <TaxpayerBanner
        banner={taxpayerBanner}
        isBannerInFeed={false}
        screen={Screen.CurrentUserProfile}
        renderLocation={TaxpayerBannerRenderLocation.Wardrobe}
      />
      <Spacer size={Spacer.Size.Large} />
    </>
  )
}

export default ProfileTaxpayerBanner
