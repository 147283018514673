import { ProfileUserInfoDto } from 'types/dtos/profile-user-info'
import { ProfileUserInfoModel } from 'types/models/profile-user-info'

export const transformProfileUserInfoDto = (
  response: ProfileUserInfoDto,
): ProfileUserInfoModel => ({
  canAddFeedback: response.can_add_feedback,
  urlForLastUnfeedbackedTransaction: response.url_for_last_unfeedbacked_transaction,
  canSendTemplates: response.can_send_templates,
  showSupportFunctionality: response.show_support_functionality,
  shadowBanned: response.shadow_banned,
  isPermanentlyBanned: response.permanently_banned,
  isTemporarilyBanned: response.temporarily_banned,
  isCompromised: response.compromised,
  isListingRestricted: response.listing_restricted,
})
