import { Response } from '@marketplace-web/shared/api-client'

import { GetSellerInsightsResp } from 'types/api/response/get-seller-insights-resp'

import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'

export const transformGetSellerInsightsResponse = (response: Response<GetSellerInsightsResp>) => ({
  isVisible: response.is_visible,
  activeItemsStats: {
    ...response.active_items_stats,
    total: transformCurrencyAmountDto(response.active_items_stats.total),
  },
  soldItemsStats: {
    ...response.sold_items_stats,
    total: transformCurrencyAmountDto(response.sold_items_stats.total),
    period: {
      ...response.sold_items_stats.period,
      total: transformCurrencyAmountDto(response.sold_items_stats.period.total),
    },
  },
})
