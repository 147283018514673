'use client'

import { ReactNode, useMemo } from 'react'

import { useSession } from '@marketplace-web/shared/session'

import VasEntryPointsContext from './VasEntryPointsContext'
import useFetchVasEntryPoints from '../../hooks/useFetchVasEntryPoints'
import { VasEntryPoint } from '../../constants/vas-entry-point'

type Props = {
  children: ReactNode
  userId: number
}

const VasEntryPointsProvider = ({ children, userId }: Props) => {
  const { user: currentUser } = useSession()

  const isViewingSelf = currentUser?.id === userId

  const { data: vasEntryPoints } = useFetchVasEntryPoints(
    [
      VasEntryPoint.ProfilePromotedClosets,
      VasEntryPoint.PromotedClosetsStats,
      VasEntryPoint.PushUps,
      VasEntryPoint.NewMemberBumpOnboarding,
    ],
    !isViewingSelf,
  )

  const value = useMemo(() => {
    return {
      vasEntryPoints,
    }
  }, [vasEntryPoints])

  return <VasEntryPointsContext.Provider value={value}>{children}</VasEntryPointsContext.Provider>
}

export default VasEntryPointsProvider
