import { withServerSideProxy } from '@marketplace-web/shared/ssr'

export const { scrollToTop, scrollToElementById } = withServerSideProxy(
  {
    scrollToTop: (behavior?: 'smooth'): void => window.scroll({ top: 0, left: 0, behavior }),
    scrollToElementById: (elementId: string, containerClass = 'site-content'): void => {
      const { document } = window

      const container = document.getElementsByClassName(containerClass)[0]
      const elementScrollTo = document.getElementById(elementId)

      if (!container || !elementScrollTo) return

      if (container instanceof HTMLElement) {
        window.scroll({
          top:
            elementScrollTo.getBoundingClientRect().top -
            container.getBoundingClientRect().top -
            container.offsetTop,
          behavior: 'smooth',
        })
      }
    },
  },
  {},
)
