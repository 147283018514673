import {
  getCurrentTimeInSeconds,
  SECONDS_PER_DAY,
  SECONDS_PER_HOUR,
  SECONDS_PER_MINUTE,
} from '@marketplace-web/shared/utils'

export const calculateRemainingTime = (futureTimeInSeconds: number) => {
  const currentTimeInSeconds = getCurrentTimeInSeconds()

  return futureTimeInSeconds - currentTimeInSeconds
}

export const formatTime = (timeInSeconds: number) => {
  const days = Math.floor(timeInSeconds / SECONDS_PER_DAY)
  const hours = Math.floor((timeInSeconds % SECONDS_PER_DAY) / SECONDS_PER_HOUR)
  const minutes = Math.floor((timeInSeconds % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE)
  const seconds = Math.floor(timeInSeconds % SECONDS_PER_MINUTE)

  return { seconds, minutes, hours, days }
}
