'use client'

import { v4 as uuid } from 'uuid'

import { navigateToPage } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { urlWithParams } from '@marketplace-web/shared/utils'

import { clickEvent } from '_libs/common/event-tracker/events'
import { getHomepageSessionIdFromUrl } from '_libs/utils/homepage'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

type RenderProps = {
  onClick: () => void
}

type Props = {
  itemId?: number | null
  url: string
  screen: Screen
  children: (props: RenderProps) => JSX.Element
}

const StartBundleButtonWrapper = ({ url, screen, children, itemId = null }: Props) => {
  const { track } = useTracking()

  const handleBuyBundleClick = () => {
    const bundleTrackingId = uuid()

    track(
      clickEvent({
        screen,
        target: ClickableElement.StartBundle,
        targetDetails: JSON.stringify({
          bundle_id: bundleTrackingId,
        }),
      }),
    )

    const bundlePageUrl = urlWithParams(url, {
      item_ids: itemId ? [itemId] : null,
      bundle_id: bundleTrackingId,
      homepage_session_id: getHomepageSessionIdFromUrl(),
    })

    navigateToPage(bundlePageUrl)
  }

  return children({ onClick: handleBuyBundleClick })
}

export default StartBundleButtonWrapper
