'use client'

import { useEffect, useRef } from 'react'

type IntervalFunction = () => void

function useInterval(callback: IntervalFunction, delay?: number | null) {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    if (!delay) return undefined

    const interval = setInterval(callbackRef.current, delay)

    return () => clearInterval(interval)
  }, [delay])
}

export default useInterval
