'use client'

import { isEqual, noop } from 'lodash'
import { memo, ReactElement } from 'react'

import ErrorBoundary from '../ErrorBoundary'
import GridItemComponent from '../GridItem'

type GridItem<T> = {
  id: number | string
  type?: string
  data: T
}

type ItemVisibleCallbackArgs<T> = {
  item: GridItem<T>
  index: number
}

type ItemGridProps<T> = {
  items: Array<GridItem<T>>
  renderItem: (item: GridItem<T>, index: number) => ReactElement | null
  widthResolver?: (item: GridItem<T>) => 'full-row' | 'one-fifth' | 'one-fourth' | null
  onItemVisible?: (callbackArgs: ItemVisibleCallbackArgs<T>) => void
  handleErrorLogging?: (err: Error | null) => void
  preventLog?: boolean
}

const Grid = <T,>({
  items,
  renderItem,
  widthResolver = () => null,
  onItemVisible = noop,
  handleErrorLogging,
  preventLog,
}: ItemGridProps<T>) => (
  <div className="feed-grid">
    {items.map((item, index) => (
      <GridItemComponent
        key={item.id}
        width={widthResolver(item)}
        onVisible={() => onItemVisible({ item, index })}
      >
        <ErrorBoundary
          FallbackComponent={ErrorBoundary.ComponentError}
          preventLog={preventLog}
          onError={handleErrorLogging}
        >
          {renderItem(item, index)}
        </ErrorBoundary>
      </GridItemComponent>
    ))}
  </div>
)

// type assertion required for generics to work
export default memo(Grid, isEqual) as typeof Grid
