'use client'

import { Card, Image, Spacer, Text } from '@vinted/web-ui'
import { useInView } from 'react-intersection-observer'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useAsset } from '@marketplace-web/shared/ui-helpers'
import { sustainabilityImpactViewEvent } from '_libs/common/event-tracker/events'

import { SELLER_INSIGHTS_ASSETS_PREFIX } from '../../../constants/insights'

type Props = {
  icon: string
  index: number
  subtitle: string
  title: string
}

const SustainabilityImpactCard = ({ icon, index, subtitle, title }: Props) => {
  const asset = useAsset(SELLER_INSIGHTS_ASSETS_PREFIX)
  const { track } = useTracking()
  const { ref } = useInView({
    onChange: inView => {
      if (!inView) return

      track(
        sustainabilityImpactViewEvent({
          iconName: icon,
          position: index + 1,
          value: title,
        }),
      )
    },
  })

  return (
    <Card key={`sustainability-impact-${title}`}>
      <div ref={ref} className="sustainability-card u-padding-large">
        <div className="u-fit-content-width">
          <Image fallbackSrc="" size={Image.Size.X2Large} src={asset(`${icon}.svg`)} />
        </div>
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Medium} />
        <div className="sustainability-card__content">
          <div className="u-block">
            <Text as="h3" text={subtitle} theme="muted" />
          </div>
          <Spacer size={Spacer.Size.Small} />
          <div className="u-fill-width">
            <Text as="h2" text={title} type={Text.Type.Heading} />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default SustainabilityImpactCard
