import { toParams } from '@marketplace-web/shared/utils'

export const getHomepageSessionIdFromUrl = (): string | undefined => {
  const urlParams = toParams(window.location.search)
  const homepageSessionId = (urlParams.homepage_session_id as string) || undefined

  return homepageSessionId
}

export const withHomepageSessionIdFromUrl = (url: string) => {
  const homepageSessionId = getHomepageSessionIdFromUrl()

  if (!homepageSessionId) return url

  const urlObject = new URL(url)
  urlObject.searchParams.set('homepage_session_id', homepageSessionId)

  return urlObject.toString()
}
