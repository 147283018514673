import { useCallback, useEffect, useState } from 'react'

import { useInterval } from '@marketplace-web/shared/ui-helpers'
import { MS_PER_SECOND, parseDateToSeconds } from '@marketplace-web/shared/utils'

import { calculateRemainingTime, formatTime } from './utils'

type FormattedRemainingTime = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

const useClosetCountdown = (endDate: string) => {
  const endTimeInSeconds = parseDateToSeconds(endDate)
  const [formattedRemainingTime, setFormattedRemainingTime] = useState<FormattedRemainingTime>()
  const [isCountdownActive, setIsCountdownActive] = useState(!!endTimeInSeconds)

  const updateRemainingTime = useCallback(() => {
    if (!endTimeInSeconds) return

    const timeLeft = calculateRemainingTime(endTimeInSeconds)

    setIsCountdownActive(timeLeft > 0)
    setFormattedRemainingTime(formatTime(timeLeft))
  }, [endTimeInSeconds])

  useEffect(updateRemainingTime, [updateRemainingTime])
  useInterval(updateRemainingTime, endTimeInSeconds ? MS_PER_SECOND : 0)

  return { formattedRemainingTime, isCountdownActive }
}

export default useClosetCountdown
