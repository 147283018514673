'use client'

import { MouseEvent } from 'react'
import { Cell, Icon, Text } from '@vinted/web-ui'
import { DonateFilled16 } from '@vinted/monochrome-icons'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import FaqEntryUrl from 'components/FaqEntryUrl'
import { UserInfoBlock } from '@marketplace-web/domain/profile'

import { UserDto } from '@marketplace-web/shared/user-data'
import { FaqEntryType } from 'constants/faq-entry'
import { donationsClickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

const ScreenToTarget = {
  [Screen.UserProfile]: ClickableElement.DonationsAboutUserProfile,
  [Screen.UserAbout]: ClickableElement.DonationsAboutUserInfo,
}

type Props = {
  user: UserDto
  screen: Screen
  styling?: ComponentProps<typeof Cell>['styling']
}

const FundraiserInfo = ({ user, screen, styling }: Props) => {
  const translate = useTranslate('user')
  const { track } = useTracking()

  const handleClick = (event: MouseEvent<Element>) => {
    if (!(event.target instanceof HTMLAnchorElement)) return

    const trackingEvent = donationsClickEvent({
      screen,
      target: ScreenToTarget[screen],
    })

    track(trackingEvent)
  }

  if (!user.fundraiser?.active) return null

  return (
    <FaqEntryUrl
      type={FaqEntryType.Donations}
      render={url => (
        <Cell onClick={handleClick} styling={styling}>
          <UserInfoBlock iconName={DonateFilled16} iconColor={Icon.Color.Success}>
            <Text
              as="span"
              text={translate('donating', {
                username: user.login,
                'faq-url': chunk => (
                  <a key="faq-url" href={url}>
                    {chunk}
                  </a>
                ),
              })}
            />
          </UserInfoBlock>
        </Cell>
      )}
    />
  )
}

export default FundraiserInfo
