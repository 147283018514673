import { ReactNode } from 'react'
import { Cell, Icon } from '@vinted/web-ui'

type Props = {
  children: ReactNode
  iconName: ComponentProps<typeof Icon>['name']
  iconColor?: ComponentProps<typeof Icon>['color']
  testId?: string
}

const UserInfoBlock = ({
  children,
  iconName,
  iconColor = Icon.Color.GreyscaleLevel2,
  testId,
}: Props) => {
  return (
    <Cell
      styling={Cell.Styling.Tight}
      testId={testId}
      prefix={
        <div className="u-flexbox u-align-items-center u-fill-height">
          <Icon name={iconName} color={iconColor} aria={{ 'aria-hidden': 'true' }} />
        </div>
      }
    >
      {children}
    </Cell>
  )
}

export default UserInfoBlock
