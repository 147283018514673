import { GetSellerInsightsPhotoRecommendationsResp } from 'types/api/response/get-seller-insights-photo-recommendations-resp'

export const transformGetSellerInsightsPhotoRecommendationsResponse = (
  response: GetSellerInsightsPhotoRecommendationsResp,
) => ({
  status: response.status,
  itemsToImprove: response.items_to_improve.map(
    ({ item_id, photo_url, accessibility_description }) => ({
      itemId: item_id,
      photoUrl: photo_url,
      accessibilityDescription: accessibility_description,
    }),
  ),
})
