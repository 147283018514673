'use client'

import { Button, Card, Cell, Icon, Image, Spacer, Text } from '@vinted/web-ui'
import { LightBulb24 } from '@vinted/monochrome-icons'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { ITEM_UPLOAD_URL } from 'constants/routes'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { clickEvent } from '_libs/common/event-tracker/events'
import { useTracking } from '@marketplace-web/shared/event-tracker'

type Props = {
  categoryTips: Array<{
    rank: number
    name: string
    icon: string
  }>
}

const SellerInsightsTipsBanner = ({ categoryTips }: Props) => {
  const translate = useTranslate('profile.seller_insights.selling_tips.dynamic_banner')
  const { track } = useTracking()

  const handleClick = () => {
    track(
      clickEvent({
        target: ClickableElement.UploadItem,
        screen: Screen.SellerInsights,
      }),
    )

    navigateToPage(ITEM_UPLOAD_URL)
  }

  const renderIcon = (icon: string) => {
    return <Image src={icon} scaling={Image.Scaling.Cover} size={Image.Size.Regular} />
  }

  if (!categoryTips?.length) return null

  return (
    <div className="u-flexbox u-flex-direction-column u-flex-grow u-flex-1">
      <Spacer size={Spacer.Size.XLarge} />
      <Card testId="seller-insights-tips-banner">
        <div className="seller-insights-atom seller-insights-tips u-padding-large">
          <Cell
            prefix={
              <Icon
                name={LightBulb24}
                color={Icon.Color.GreyscaleLevel2}
                aria={{ 'aria-hidden': true }}
              />
            }
            title={translate('title')}
            body={translate('subtitle')}
            styling={Cell.Styling.Tight}
          />
          <Cell styling={Cell.Styling.Tight}>
            <div className="u-flexbox u-flex-direction-row u-padding-medium">
              {categoryTips.map(({ name, icon }) => (
                <section
                  key={`category-tip-${name}`}
                  className="u-flexbox u-flex-direction-column u-align-items-center u-half-width"
                >
                  <div className="seller-insights-tips-icon">{renderIcon(icon)}</div>
                  <Spacer size={Spacer.Size.Small} />
                  <Text
                    alignment={Text.Alignment.Center}
                    text={name}
                    type={Text.Type.Subtitle}
                    as="h3"
                  />
                </section>
              ))}
            </div>
          </Cell>
          <Button
            text={translate('actions.list_item')}
            size={Button.Size.Small}
            styling={Button.Styling.Filled}
            onClick={handleClick}
          />
        </div>
      </Card>
    </div>
  )
}

export default SellerInsightsTipsBanner
