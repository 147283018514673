'use client'

import { Button, Cell, Divider, Dialog, Navigation, Spacer, Text } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import { useTranslate } from '@marketplace-web/shared/i18n'

type Props = {
  show: boolean
  onCloseOrConfirm: () => void
}

const SustainabilityImpactValuePropositionModal = ({ show, onCloseOrConfirm }: Props) => {
  const translate = useTranslate('profile.seller_insights.sustainability_impact.modal')
  const commonTranslate = useTranslate('common')

  const handleOnClose = () => {
    onCloseOrConfirm()
  }

  const renderModalBody = () => {
    return (
      <div className="u-fill-width">
        <Navigation
          body={translate('title')}
          right={
            <Button
              inverse
              inline
              iconName={X24}
              onClick={handleOnClose}
              aria={{
                'aria-label': commonTranslate('a11y.actions.dialog_close'),
              }}
            />
          }
        />
        <Divider />
        <Cell>
          <Text inline html as="p" text={translate('body.one')} />
          <Spacer size={Spacer.Size.XLarge} />
          <Button
            text={commonTranslate('actions.dialog_close')}
            styling={Button.Styling.Filled}
            size={Button.Size.Medium}
            onClick={onCloseOrConfirm}
            testId="sustinability-impact-modal-confirm-button"
          />
        </Cell>
      </div>
    )
  }

  return (
    <Dialog
      hasScrollableContent
      defaultCallback={handleOnClose}
      show={show}
      testId="sustainability-impact-value-proposition-modal"
    >
      {renderModalBody()}
    </Dialog>
  )
}

export default SustainabilityImpactValuePropositionModal
