'use client'

import { useEffect, useState } from 'react'

import { PromoAfterItemUpload } from '../../../containers/PromoAfterItemUploadContext'
import usePromoAfterItemUploadContext from '../../../hooks/usePromoAfterItemUploadContext'
import ItemUploadFeedback from './ItemUploadFeedback'
import ItemUploadFeedbackNotification from './ItemUploadFeedbackNotification'
import ListPromotionModal from './ListPromotionModal'

const PromosAfterItemUpload = () => {
  const [isFeedbackNotificationOpen, setIsFeedbackNotificationOpen] = useState(false)
  const {
    currentPromotion,
    uploadedItemId: newItemId,
    uploadSessionId,
    promotions: { uploadAnotherItemTip: showUploadAnotherItemTip },
    setNextPromotion,
  } = usePromoAfterItemUploadContext()

  useEffect(() => {
    setNextPromotion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNotificationShow = () => {
    setIsFeedbackNotificationOpen(true)
  }

  const closeNotification = () => {
    setIsFeedbackNotificationOpen(false)
  }

  return (
    <>
      {currentPromotion === PromoAfterItemUpload.ListAnotherItem && (
        <ListPromotionModal
          newItemId={newItemId}
          uploadSessionId={uploadSessionId}
          showUploadAnotherItemTip={showUploadAnotherItemTip}
          setNextPromotion={setNextPromotion}
        />
      )}
      {currentPromotion === PromoAfterItemUpload.ItemUploadFeedback && (
        <ItemUploadFeedback onClose={handleNotificationShow} />
      )}
      {isFeedbackNotificationOpen && (
        <ItemUploadFeedbackNotification handleClose={closeNotification} />
      )}
    </>
  )
}

export default PromosAfterItemUpload
