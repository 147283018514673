import { Response } from '@marketplace-web/shared/api-client'
import { FeedbackRatingOptionsResp } from 'types/api/response/feedback-rating-options-resp'
import { GetItemUploadFeedbackRatingsResp } from 'types/api/response/get-item-upload-feedback-ratings-resp'
import { GetOfflineVerificationFeedbackResp } from 'types/api/response/get-offline-verification-feedback-resp'
import { FeedbackRatingModel } from 'types/models/help-center-feedback'
import { transformFeedbackFormDto } from 'data/transformers/feedback-form'

export const transformFeedbackRatingsResponse = (
  response: Response<
    | FeedbackRatingOptionsResp
    | GetItemUploadFeedbackRatingsResp
    | GetOfflineVerificationFeedbackResp
  >,
): FeedbackRatingModel | undefined => {
  if (!response.feedback_ratings) return undefined

  return transformFeedbackFormDto(response.feedback_ratings)
}
