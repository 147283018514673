import { UPLOADED_ITEM_PROMOTIONS } from '@marketplace-web/domain/item-upload'
import { PromotionStorageKeys } from '@marketplace-web/domain/item-upload-data'
import { getLocalStorageItem, removeLocalStorageItem } from '@marketplace-web/shared/browser'

export const getPromotionsFromLocalStorage = () => {
  const uploadedItemPromotionsFromStorage = getLocalStorageItem(UPLOADED_ITEM_PROMOTIONS)
  const parsedItemPromotions: Array<PromotionStorageKeys> = JSON.parse(
    uploadedItemPromotionsFromStorage || '[]',
  )

  removeLocalStorageItem(UPLOADED_ITEM_PROMOTIONS)

  return {
    bumped: parsedItemPromotions.includes(PromotionStorageKeys.ShowBumped),
    feedback: parsedItemPromotions.includes(PromotionStorageKeys.ShowFeedback),
    uploadAnotherItemTip: parsedItemPromotions.includes(
      PromotionStorageKeys.ShowUploadAnotherItemTip,
    ),
  }
}
