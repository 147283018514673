import { ItemThumbnailSize, transformItemPhotoDtos } from '@marketplace-web/domain/item-data'
import { getItemThumbnail } from 'data/utils/item'
import { WardrobeItemDto } from 'types/dtos/wardrobe-item'
import { ProductItemModel } from 'types/models/product-item'

import { transformCurrencyAmountDto } from './currency-amount'
import { transformItemBoxDto } from './item-box'
import { getDominantColor } from './product-item'

export const transformWardrobeItemDtoToProductItem = (item: WardrobeItemDto): ProductItemModel => ({
  id: item.id,
  title: item.title,
  url: item.url,
  photos: transformItemPhotoDtos(item.photos),
  viewCount: item.view_count,
  favouriteCount: item.favourite_count,
  user: { id: item.user.id, isBusiness: item.user.business },
  priceWithDiscount: item.discount_price ? transformCurrencyAmountDto(item.discount_price) : null,
  price: transformCurrencyAmountDto(item.price),
  serviceFee: item.service_fee ? transformCurrencyAmountDto(item.service_fee) : null,
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDto(item.total_item_price)
    : undefined,
  thumbnailUrl: getItemThumbnail(item.photos, ItemThumbnailSize.Large),
  dominantColor: getDominantColor(item.photos),
  badge: item.badge,
  itemAlertType: item.item_alert_type,
  itemClosingAction: item.item_closing_action,
  isPromoted: item.promoted,
  isDraft: item.is_draft,
  isReserved: item.is_reserved,
  isHidden: item.is_hidden,
  isClosed: item.is_closed,
  isFavourite: item.is_favourite,
  isProcessing: item.is_processing,
  canPushUp: item.can_push_up,
  canEdit: item.can_edit,
  areStatsVisible: item.stats_visible,
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
})

export const transformWardrobeItemDtosToProductItems = (
  items: Array<WardrobeItemDto>,
): Array<ProductItemModel> => items.map(transformWardrobeItemDtoToProductItem)
