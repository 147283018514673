'use client'

import { ContentLoader } from '@marketplace-web/shared/ui-helpers'
import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'

import { type SellerInsightsType } from '../../constants/insights'
import useGetPhotoRecommendations from '../../hooks/useGetPhotoRecommendations'
import SellerInsightsPhotoRecommendations from './SellerInsightsPhotoRecommendations/SellerInsightsPhotoRecommendations'
import SellerInsightsBlocks from './SellerInsightsBlocks'
import SellerInsightsTipsBanner from './SellerInsightsTipsBanner'
import SustainabilityImpact from './SustainabilityImpact'
import useGetCategoryTips from '../../hooks/useGetCategoryTips'

type Props = {
  sellerInsights: SellerInsightsType
  isSellerInsightsLoading: boolean
}

const SellerInsights = ({ sellerInsights, isSellerInsightsLoading }: Props) => {
  const { photoRecommendations, isPhotoRecommendationsLoading } = useGetPhotoRecommendations()
  const { categoryTips, isFetchCategoryTipsLoading } = useGetCategoryTips()

  const dynamicTipsBannerAbTest = useAbTest('seller_insights_dynamic_tip_banner')
  const isDynamicTipsBannerAbTestEnabled =
    dynamicTipsBannerAbTest && dynamicTipsBannerAbTest.variant !== 'off'
  useTrackAbTest(dynamicTipsBannerAbTest)

  const sustainabilityImpactAbTest = useAbTest('seller_insights_sustainability')
  const isSustainabilityImpactAbTestEnabled =
    sustainabilityImpactAbTest && sustainabilityImpactAbTest.variant !== 'off'
  useTrackAbTest(sustainabilityImpactAbTest)

  const isCategoryTipsRendered = isDynamicTipsBannerAbTestEnabled && categoryTips

  if (isSellerInsightsLoading || isPhotoRecommendationsLoading || isFetchCategoryTipsLoading)
    return <ContentLoader testId="seller-insights-loader" />

  if (!sellerInsights?.isVisible) return null

  return (
    <section>
      {isSustainabilityImpactAbTestEnabled && <SustainabilityImpact />}
      <div className="seller-blocks-container">
        {isCategoryTipsRendered && <SellerInsightsTipsBanner categoryTips={categoryTips} />}
        {sellerInsights?.isVisible && (
          <SellerInsightsBlocks categoryTips={categoryTips} sellerInsights={sellerInsights} />
        )}
      </div>
      {photoRecommendations && <SellerInsightsPhotoRecommendations {...photoRecommendations} />}
    </section>
  )
}

export default SellerInsights
