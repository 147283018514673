import { useEffect } from 'react'
import { camelCase, startCase } from 'lodash'

import { getCategoryTips } from 'data/api'
import { useFetch } from '@marketplace-web/shared/api-client'
import { useAbTest } from '@marketplace-web/shared/ab-tests'

const useGetCategoryTips = () => {
  const {
    fetch: fetchCategoryTips,
    isLoading: isFetchCategoryTipsLoading,
    data,
  } = useFetch(getCategoryTips)

  const dynamicTipsBannerAbTest = useAbTest('seller_insights_dynamic_tip_banner')

  const isDynamicTipsBannerAbTestEnabled =
    dynamicTipsBannerAbTest && dynamicTipsBannerAbTest.variant !== 'off'

  useEffect(() => {
    if (!isDynamicTipsBannerAbTestEnabled) return

    fetchCategoryTips()
  }, [fetchCategoryTips, isDynamicTipsBannerAbTestEnabled])

  const dataWithIconInPascalCase = data?.category_tips.map(({ rank, name, icon }) => ({
    rank,
    name,
    icon: startCase(camelCase(icon)).replace(/ /g, ''),
  }))

  return {
    categoryTips: dataWithIconInPascalCase,
    isFetchCategoryTipsLoading,
  }
}

export default useGetCategoryTips
