'use client'

import {
  Block16,
  CheckCircle16,
  CheckmarkShield16,
  Clock16,
  Document16,
  Envelope16,
  Flag16,
  HorizontalDots24,
  LocationPin16,
  Member16,
  Pencil24,
  Rss16,
  X16,
} from '@vinted/monochrome-icons'
import { Button, Card, Cell, Divider, Icon, Image, Rating, Spacer, Text } from '@vinted/web-ui'
import { compact, isEmpty, noop } from 'lodash'
import { ReactNode, useRef, useState } from 'react'

import { FundraiserInfo } from '@marketplace-web/domain/fundraisers'
import { SellerBadge, SellerBadgeType } from '@marketplace-web/domain/seller-engagement-tools'
import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { HideAtBreakpoint, ShowAtBreakpoint } from '@marketplace-web/shared/breakpoints'
import { navigateToPage, reloadPage, useLocation } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { FormattedDateMessage, FormattedMessage, useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import {
  EMPTY_USER_IMAGE_NAME,
  LinkifiedMessage,
  SeparatedList,
  useAsset,
  UserTitle,
} from '@marketplace-web/shared/ui-helpers'
import { UserDto } from '@marketplace-web/shared/user-data'
import { isDateInTheFuture, urlWithParams } from '@marketplace-web/shared/utils'

import { clickEvent, followUserEvent } from '_libs/common/event-tracker/events'
import DropdownMenu from 'components/DropdownMenu'
import FollowButton from 'components/FollowButton'
import TranslationControl from 'components/TranslationControl'
import UserBlockModal from 'components/UserBlockModal'
import { AlertType } from 'constants/admin'
import {
  MEMBER_RESTORE_URL,
  MEMBER_TEMPLATES_URL,
  NEW_ADMIN_ALERT_URL,
  PROFILE_SETTINGS_URL,
} from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { Translation } from 'constants/translation'
import { blockUser, getUserById, unblockUser } from 'data/api'
import { calculateRating } from 'data/utils/user-rating'
import UnauthenticatedReportModal from 'pages/Profile/UnauthenticatedReportModal'
import { SellerBadgeDto } from 'types/dtos/seller-badge'

import { USER_STATUS_MEMBER_DELETED, VERIFICATION_LIST } from '../../../constants'
import { getIsAccountBanPermanent } from '../../../utils/user'
import FollowInfo from '../FollowInfo'
import UserInfoBlock from '../InfoHeader/UserInfoBlock'
import ProfileInfoBlock from './ProfileInfoBlock'

type Content = {
  about: string
}

const renderWarningBlock = (key: string, text: ReactNode, enableHtml?: boolean) => {
  const data = {
    text,
    enableHtml,
    iconName: key === 'deleted' ? X16 : Block16,
    theme: 'warning' as const,
    color: Icon.Color.Warning,
  }

  return <ProfileInfoBlock key={key} useFlexbox data={data} />
}

const renderInfoBlock = (
  text: string | JSX.Element,
  iconName: ComponentProps<typeof Icon>['name'],
  color?: ComponentProps<typeof Icon>['color'],
) => {
  const data = { text, iconName, color }

  return <ProfileInfoBlock useFlexbox data={data} />
}

type Props = {
  compactMode?: boolean
  onUserInfoClick?: () => void
  onTogglePoliciesModal?: () => void
  user: UserDto
  sellerBadgesList: Array<Omit<SellerBadgeDto, 'progress'>> | undefined
  screen: Screen
  viewingSelf: boolean
  showActions: boolean
  isShadowBanned?: boolean
  showSupportFunctionality: boolean
  canSendTemplates: boolean
  isContentTranslationEnabled: boolean
}

const ProfileInfo = ({
  compactMode = false,
  onUserInfoClick,
  user,
  sellerBadgesList,
  screen,
  viewingSelf = false,
  showActions = true,
  isShadowBanned = false,
  showSupportFunctionality,
  canSendTemplates,
  isContentTranslationEnabled,
  onTogglePoliciesModal = noop,
}: Props) => {
  const { relativeUrl } = useLocation()
  const translate = useTranslate('user')
  const { track } = useTracking()
  const asset = useAsset('assets/no-photo')

  const isFastReplierBadgeEnabled = useFeatureSwitch('web_fast_replier_badge')

  const fastReplierBadgeAbTest = useAbTest('fast_replier_badge_v3')
  const showFastReplierBadge = isFastReplierBadgeEnabled && fastReplierBadgeAbTest?.variant === 'on'

  const [isContentTranslated, setIsContentTranslated] = useState(false)
  const [translatedContent, setTranslatedContent] = useState<Content | null>(null)
  const [isBlockModalVisible, setIsBlockModalVisible] = useState(false)
  const [isReportModalVisible, setIsReportModalVisible] = useState(false)
  const isFollowTracked = useRef(false)

  const isProfileReportingModalEnabled = useFeatureSwitch('ntd_form_user')
  const isProfileBlockingImprovementsEnabled = useFeatureSwitch('profile_blocking_improvements')
  const isUKCompanyNumberHidden = useFeatureSwitch('hide_uk_sole_traders_company_number')

  const { user: currentUser } = useSession()

  const { business: isBusinessAccount } = user

  async function translateDescription() {
    if (translatedContent) {
      setIsContentTranslated(true)

      return
    }

    const response = await getUserById({ id: user.id, localize: true })

    if ('errors' in response) return

    const { about } = response.user

    if (!about) return

    setTranslatedContent({ about })
    setIsContentTranslated(true)
  }

  function showOriginal() {
    setIsContentTranslated(false)
  }

  function handleTranslate(isTranslated: boolean) {
    return isTranslated ? showOriginal() : translateDescription()
  }

  const toggleBlock = async () => {
    if (!currentUser) return

    const toggleFunction = user.is_hated ? unblockUser : blockUser

    await toggleFunction({ currentUserId: currentUser.id, blockedUserId: user.id })
    reloadPage()
  }

  function handleBlockCancel() {
    setIsBlockModalVisible(false)
  }

  function handleModalBlockButtonClick() {
    setIsBlockModalVisible(false)
    reloadPage()
  }

  function handleBlockClick() {
    if (isProfileBlockingImprovementsEnabled) {
      setIsBlockModalVisible(true)

      return
    }

    toggleBlock()
  }

  function handleReportClick() {
    if (currentUser || !isProfileReportingModalEnabled) {
      const url = urlWithParams(NEW_ADMIN_ALERT_URL, {
        ref_id: user.id,
        ref_type: AlertType.Member,
        ref_url: relativeUrl,
      })

      navigateToPage(url)

      return
    }

    setIsReportModalVisible(true)
  }

  function handleReportModalClose() {
    setIsReportModalVisible(false)
  }

  function renderProfileImage() {
    const photoUrl = user.photo ? user.photo.url : asset(EMPTY_USER_IMAGE_NAME)

    return <Image src={photoUrl} scaling={Image.Scaling.Fill} />
  }

  function getRestrictionTranslationKey(key: string, isRestrictionPermanent: boolean) {
    const isDateVisibleForUser = viewingSelf || showSupportFunctionality || user.is_god

    return isDateVisibleForUser && !isRestrictionPermanent ? key : `${key}_no_date`
  }

  function renderInfoHeader() {
    const { localization, id } = user
    const activeContent = isContentTranslated && translatedContent ? translatedContent : user

    const showTranslationControl =
      isContentTranslationEnabled && localization && localization !== Translation.None

    const showUserActions = showSupportFunctionality || !viewingSelf

    const toggleBlockText = user.is_hated ? translate('action.unblock') : translate('action.block')

    const actions = [
      {
        id: 'report',
        text: translate('action.report'),
        onClick: handleReportClick,
      },
      { id: user.is_hated ? 'unblock' : 'block', text: toggleBlockText, onClick: handleBlockClick },
    ]

    const dropdownButtonProps: ComponentProps<typeof Button> = {
      styling: Button.Styling.Flat,
      icon: (
        <Icon
          name={HorizontalDots24}
          color={Icon.Color.GreyscaleLevel2}
          testId="actions-menu-button"
        />
      ),
      size: Button.Size.Medium,
    }

    const aboutText = activeContent?.about ?? ''

    return (
      <ProfileInfoBlock>
        <Cell>
          <div className="u-flexbox u-justify-content-between">
            <Text
              as="h1"
              type={Text.Type.Heading}
              truncate
              text={
                <UserTitle
                  businessAccountName={user.business_account?.name}
                  login={user.login}
                  isBusiness={user.business}
                  isBadgeHidden
                  isLoginForced
                />
              }
            />
            {showUserActions && (
              <div>
                <DropdownMenu actions={actions} buttonProps={dropdownButtonProps} />
              </div>
            )}
          </div>
          {aboutText && (
            <>
              <Text as="span" format>
                <LinkifiedMessage
                  text={aboutText}
                  trackingData={{
                    user_id: user.id.toString(),
                  }}
                />
              </Text>
              {showTranslationControl && (
                <>
                  <Spacer />
                  <TranslationControl
                    isTranslated={isContentTranslated}
                    autotranslate={false}
                    onClick={handleTranslate}
                    tracking={{
                      target: ClickableElement.TranslateDescription,
                      targetDetails: id.toString(),
                    }}
                  />
                </>
              )}
            </>
          )}
        </Cell>
      </ProfileInfoBlock>
    )
  }

  function renderBlockedWarningBlock() {
    return (
      <ProfileInfoBlock key="blocked-warning">
        <Cell>
          <UserInfoBlock iconName={Block16} iconColor={Icon.Color.Warning}>
            <Text as="span" theme="warning">
              {translate('admin.messages.blocked')}
            </Text>
          </UserInfoBlock>
          {isProfileBlockingImprovementsEnabled && (
            <>
              <Spacer size={Spacer.Size.Large} />
              <Button
                text={translate('action.unblock')}
                styling={Button.Styling.Filled}
                onClick={handleBlockClick}
              />
            </>
          )}
        </Cell>
      </ProfileInfoBlock>
    )
  }

  function renderSellerPolicies() {
    if (!isBusinessAccount) return null

    return (
      <UserInfoBlock iconName={Document16}>
        <button type="button" onClick={onTogglePoliciesModal}>
          <Text clickable text={translate('seller_policies.title')} as="span" />
        </button>
      </UserInfoBlock>
    )
  }

  function renderWarningParts() {
    const { is_hated, account_ban_date, account_status, id, is_account_banned } = user

    const showDeleted = account_status === USER_STATUS_MEMBER_DELETED
    const isAccountBanPermanent = getIsAccountBanPermanent(account_ban_date)

    const parts = compact([
      !is_hated &&
        is_account_banned &&
        renderWarningBlock(
          'banned',
          translate(getRestrictionTranslationKey('admin.messages.banned', isAccountBanPermanent), {
            date: account_ban_date,
          }),
        ),
      is_hated && renderBlockedWarningBlock(),
      isShadowBanned &&
        renderWarningBlock('on_hold_no_date', translate('admin.messages.on_hold_no_date')),
      showDeleted &&
        renderWarningBlock(
          'deleted',
          translate('admin.messages.deleted', {
            'member-restore-url': chunks => <a href={MEMBER_RESTORE_URL(id)}>{chunks}</a>,
          }),
          true,
        ),
    ])

    if (isEmpty(parts)) return null

    return parts
  }

  function renderFeedback() {
    const { feedback_reputation, feedback_count } = user
    const rating = calculateRating(feedback_reputation)

    return (
      <ProfileInfoBlock>
        <Cell type={Cell.Type.Navigating} onClick={onUserInfoClick} chevron>
          <Rating
            aria={{
              'aria-label': (rating && translate('a11y.rating', { rating, max_rating: 5 })) || '',
            }}
            value={rating}
            text={translate(
              'feedback.reviews',
              { count: feedback_count },
              { count: feedback_count },
            )}
            emptyStateText={translate('feedback.no_reviews')}
          />
        </Cell>
      </ProfileInfoBlock>
    )
  }

  function renderDonation() {
    if (!user.fundraiser?.active) return null

    return (
      <ProfileInfoBlock>
        <FundraiserInfo user={user} screen={screen} />
      </ProfileInfoBlock>
    )
  }

  function renderMemberStatuses() {
    const { moderator, volunteer_moderator, is_identity } = user

    const parts = compact([
      moderator &&
        renderInfoBlock(translate('status.product_team'), CheckmarkShield16, Icon.Color.Primary),
      volunteer_moderator &&
        renderInfoBlock(
          translate('status.volunteer_moderator'),
          CheckmarkShield16,
          Icon.Color.Primary,
        ),
      is_identity &&
        renderInfoBlock(translate('status.identity_confirmed'), CheckCircle16, Icon.Color.Success),
    ])

    if (isEmpty(parts)) return null

    return parts
  }

  function renderBusinessEmail() {
    const hideBusinessEmail = isBusinessAccount && user.business_account?.personal_email
    const businessEmail = isBusinessAccount && user.business_account?.email

    if (!businessEmail) return null

    return (
      <UserInfoBlock iconName={Envelope16}>
        {hideBusinessEmail ? (
          <span>{businessEmail}</span>
        ) : (
          <a href={`mailto:${businessEmail}`}>{businessEmail}</a>
        )}
      </UserInfoBlock>
    )
  }

  function renderBusinessPhone() {
    const hideBusinessPhoneNumber =
      isBusinessAccount && user.business_account?.personal_phone_number
    const businessPhone = isBusinessAccount && user.business_account?.phone_number

    if (!businessPhone) return null

    return (
      <UserInfoBlock iconName={Envelope16}>
        {hideBusinessPhoneNumber ? (
          <span>{businessPhone}</span>
        ) : (
          <a href={`tel:${businessPhone}`}>{businessPhone}</a>
        )}
      </UserInfoBlock>
    )
  }

  function renderBusinessVerifications() {
    if (!isBusinessAccount) return null

    const businessLegalCode = user.business_account?.legal_code
    const hideBusinessLegalCode =
      isUKCompanyNumberHidden &&
      user.business_account?.country === 'UK' &&
      user.business_account.entity_type === 'sole_trader'
    const businessRegistrarName = user.business_account?.registrar_name
    const selfCertified = user.business_account?.verified_identity

    return (
      <>
        <UserInfoBlock iconName={CheckCircle16}>
          {translate('verification.business_identity')}
        </UserInfoBlock>

        {businessLegalCode && !hideBusinessLegalCode && (
          <UserInfoBlock iconName={CheckCircle16}>
            {translate('verification.legal_code')} {businessLegalCode}
          </UserInfoBlock>
        )}

        {businessRegistrarName && (
          <UserInfoBlock iconName={CheckCircle16} testId="business-verification-registrar-name">
            {businessRegistrarName}
          </UserInfoBlock>
        )}

        {selfCertified && (
          <UserInfoBlock iconName={CheckCircle16} testId="business-verification-self-certified">
            {translate('verification.complies_with_eu_law')}
          </UserInfoBlock>
        )}
      </>
    )
  }

  function renderVerifications() {
    const filteredVerifications = VERIFICATION_LIST.filter(item => user.verification[item]?.valid)

    if (isEmpty(filteredVerifications) && !isBusinessAccount) return null

    if (!compactMode) {
      return (
        <ProfileInfoBlock useFlexbox>
          <div>
            <Text
              as="h4"
              type={Text.Type.Caption}
              text={<FormattedMessage id="user.verification.title" />}
            />
            {filteredVerifications.map(verification => (
              <UserInfoBlock key={verification} iconName={CheckCircle16}>
                {translate(`verification.${verification}`)}
              </UserInfoBlock>
            ))}
            {renderBusinessVerifications()}
          </div>
        </ProfileInfoBlock>
      )
    }

    return (
      <UserInfoBlock iconName={CheckCircle16}>
        {filteredVerifications
          .map(item => translate(`verification.${item}`))
          .concat(isBusinessAccount ? [translate('verification.business_identity')] : [])
          .join(', ')}
      </UserInfoBlock>
    )
  }

  const renderLocationContent = () => {
    const { expose_location, city, country_title_local, business_account } = user

    const hideBusinessLocation =
      isBusinessAccount && business_account?.entity_type === 'sole_trader'
    const businessAddress = isBusinessAccount && business_account?.address
    const locationDescription =
      expose_location && city ? [city, country_title_local].join(', ') : country_title_local

    if (locationDescription && !isBusinessAccount) {
      return locationDescription
    }

    if (businessAddress && hideBusinessLocation) {
      const businessLocationDescription =
        expose_location && city
          ? [businessAddress.city, businessAddress.country_title].join(', ')
          : businessAddress.country_title

      return businessLocationDescription
    }

    if (businessAddress && !hideBusinessLocation) {
      return (
        <>
          {businessAddress?.line1 && <div>{businessAddress.line1}</div>}
          {businessAddress?.line2 && <div>{businessAddress.line2}</div>}
          {businessAddress?.postal_code && businessAddress?.city && (
            <div>
              {businessAddress.postal_code} {businessAddress.city}
            </div>
          )}
          {businessAddress?.country_title && <div>{businessAddress.country_title}</div>}
        </>
      )
    }

    return null
  }

  const renderLocationBlock = () => {
    if (!renderLocationContent()) {
      return null
    }

    return (
      <UserInfoBlock iconName={LocationPin16} testId="profile-location-info">
        {renderLocationContent()}
      </UserInfoBlock>
    )
  }

  const trackFollowToggle = ({ isFollowEvent }: { isFollowEvent: boolean }) => {
    track(followUserEvent({ userId: user.id, isFollowEvent }))

    if (isFollowTracked.current) return

    isFollowTracked.current = true
    track(clickEvent({ target: ClickableElement.Follow, screen: Screen.UserProfile }))
  }

  function renderFollowMessage() {
    const { followers_count, following_count, id } = user

    return (
      <FollowInfo
        followersCount={followers_count}
        followingCount={following_count}
        userId={id}
        viewingSelf={viewingSelf}
      />
    )
  }

  function renderAdditionalInfo() {
    const { email, last_loged_on_ts, created_at, msg_template_count } = user

    const memberSinceDate = new Date(created_at)

    return (
      <ProfileInfoBlock useFlexbox>
        <div>
          {renderLocationBlock()}
          {renderBusinessEmail()}
          {renderBusinessPhone()}

          {last_loged_on_ts && (
            <UserInfoBlock iconName={Clock16}>
              <Text as="span">
                <FormattedDateMessage
                  id="user.last_login"
                  date={new Date(last_loged_on_ts)}
                  relativeDate
                />
              </Text>
            </UserInfoBlock>
          )}

          <UserInfoBlock iconName={Rss16}>{renderFollowMessage()}</UserInfoBlock>
          {showSupportFunctionality && (
            <UserInfoBlock iconName={Member16}>
              <Text as="span">
                <FormattedDateMessage id="user.since" date={memberSinceDate} relativeDate={false} />
              </Text>
            </UserInfoBlock>
          )}
          {showSupportFunctionality && (
            <UserInfoBlock iconName={Envelope16}>
              <Text as="span" text={email} />
            </UserInfoBlock>
          )}
          {canSendTemplates && (
            <UserInfoBlock iconName={Flag16}>
              <a href={MEMBER_TEMPLATES_URL(user.id)}>
                {translate(
                  'admin.template_count',
                  { count: msg_template_count },
                  { count: msg_template_count },
                )}
              </a>
            </UserInfoBlock>
          )}
          {renderSellerPolicies()}
        </div>
      </ProfileInfoBlock>
    )
  }

  function renderTopActions() {
    if (!viewingSelf) return null

    return (
      <ProfileInfoBlock useFlexbox>
        <Button
          icon={<Icon name={Pencil24} color={Icon.Color.GreyscaleLevel2} />}
          url={PROFILE_SETTINGS_URL}
          text={translate('action.edit_profile')}
          theme="muted"
          size={Button.Size.Medium}
        />
      </ProfileInfoBlock>
    )
  }

  function renderBottomActions() {
    const showMessageButton = showActions && !viewingSelf && user.allow_direct_messaging
    const showFollowButton =
      showActions && !viewingSelf && !isDateInTheFuture(user.closet_countdown_end_date)
    const isActionAvailable = showMessageButton || showFollowButton

    if (!isActionAvailable || user.is_account_banned || !showFollowButton) return null

    return (
      <ProfileInfoBlock useFlexbox>
        <FollowButton
          onFollowToggle={trackFollowToggle}
          userId={user.id}
          isActive={user.is_favourite}
          size={Button.Size.Medium}
          testId="profile-info-follow-button"
        />
      </ProfileInfoBlock>
    )
  }

  function renderCompactUserInfo() {
    const { feedback_reputation, photo, feedback_count } = user
    const rating = calculateRating(feedback_reputation)
    const photoUrl = photo ? photo.url : asset(EMPTY_USER_IMAGE_NAME)

    const infoProps = {
      prefix: <Image src={photoUrl} styling={Image.Styling.Circle} size={Image.Size.Large} />,
      title: (
        <UserTitle
          businessAccountName={user.business_account?.name}
          login={user.login}
          isBusiness={user.business}
        />
      ),
      body: (
        <>
          <Rating
            aria={{
              'aria-label': (rating && translate('a11y.rating', { rating, max_rating: 5 })) || '',
            }}
            value={rating}
            text={feedback_count}
            emptyStateText={translate('feedback.no_reviews')}
          />
          <Spacer size={Spacer.Size.Small} />
        </>
      ),
      type: Cell.Type.Navigating,
      onClick: onUserInfoClick,
      chevron: true,
      id: 'profile-compact-info',
    }

    return <Cell {...infoProps} />
  }

  function renderCompactAdditionalInfo() {
    return (
      <Cell>
        {renderVerifications()}
        {renderLocationBlock()}
        {renderBusinessEmail()}
        <UserInfoBlock iconName={Rss16}>{renderFollowMessage()}</UserInfoBlock>
      </Cell>
    )
  }

  function renderMainInfo() {
    return (
      <SeparatedList separator={<Divider />}>
        {renderTopActions()}
        {renderInfoHeader()}
        {renderWarningParts()}
        {renderDonation()}
        {renderFeedback()}
        {renderMemberStatuses()}
        {renderVerifications()}
        {renderAdditionalInfo()}
        {renderBottomActions()}
      </SeparatedList>
    )
  }

  function renderUserBlockModal() {
    return (
      <UserBlockModal
        username={user.login}
        isUserBlocked={user.is_hated}
        userId={user.id}
        isVisible={isBlockModalVisible}
        onCancel={handleBlockCancel}
        onConfirm={handleModalBlockButtonClick}
      />
    )
  }

  const renderSellerBadges = () => {
    if (!showFastReplierBadge) {
      if (viewingSelf) return null

      const { seller_badges } = user

      if (!seller_badges?.length) return null

      const activeListerBadge = seller_badges.find(
        badge => badge.type === SellerBadgeType.ActiveLister,
      )

      return activeListerBadge ? (
        <Cell>
          <SellerBadge type={activeListerBadge.type} />
        </Cell>
      ) : null
    }

    if (!sellerBadgesList?.length) return null

    return (
      <Cell>
        <div className="u-flexbox u-flex-direction-column">
          <SeparatedList separator={<Spacer size={Spacer.Size.Large} />}>
            {sellerBadgesList.map((badge, index) => (
              <SellerBadge type={badge.type} key={index} />
            ))}
          </SeparatedList>
        </div>
      </Cell>
    )
  }

  if (compactMode) {
    return (
      <SeparatedList separator={<Divider />}>
        {renderCompactUserInfo()}
        {renderDonation()}
        {renderSellerBadges()}
        {renderCompactAdditionalInfo()}
        {renderBottomActions()}
      </SeparatedList>
    )
  }

  return (
    <>
      <HideAtBreakpoint breakpoint="tablets">
        <Card>
          <div className="u-overflow-hidden">
            {renderProfileImage()}
            {renderMainInfo()}
          </div>
        </Card>
      </HideAtBreakpoint>
      <ShowAtBreakpoint breakpoint="tablets">
        <Spacer size={Spacer.Size.Large} />
        <div className="u-flexbox">
          <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />
          <Card>
            <div className="u-overflow-hidden">{renderProfileImage()}</div>
          </Card>
          <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />
          <div className="u-flex-grow">
            <Card>
              <div className="u-overflow-hidden">{renderMainInfo()}</div>
            </Card>
          </div>
          <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />
        </div>
      </ShowAtBreakpoint>
      <UnauthenticatedReportModal
        isVisible={isReportModalVisible}
        onClose={handleReportModalClose}
      />
      {renderUserBlockModal()}
    </>
  )
}

export default ProfileInfo
