import { uniqBy } from 'lodash'
import { useCallback, useRef, useState } from 'react'

import { useFetch } from '@marketplace-web/shared/api-client'
import { SortByOption } from 'constants/filter'
import { ItemAlertStatus } from 'constants/item'
import { getWardrobeItems } from 'data/api'
import { transformWardrobeItemsResponse } from 'data/api/transformers/response/transform-wardrobe-items-response'
import { ProductItemModel } from 'types/models/product-item'

import { SellerFiltersType } from '../pages/Profile/UserItems/SellerFilters/utils'

type Props = {
  userId: number
}

const filterReplicaItems = (items: Array<ProductItemModel>): Array<ProductItemModel> =>
  items.filter(item => item.itemAlert?.itemAlertType === ItemAlertStatus.ReplicaProof)

const STARTING_PAGE_INDEX = 0
const DEFAULT_SORT_BY = SortByOption.Relevance
const DEFAULT_PAGE_SIZE = 20

const useWardrobeItems = ({ userId }: Props) => {
  const [items, setItems] = useState<Array<ProductItemModel>>([])
  const [currentPage, setCurrentPage] = useState(STARTING_PAGE_INDEX)
  const [endReached, setEndReached] = useState(false)
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_BY)
  const [catalogId, setCatalogId] = useState<number | null>(null)
  const [selectedSellerFilter, setSelectedSellerFilter] = useState<SellerFiltersType | undefined>(
    undefined,
  )
  const [itemsCount, setItemsCount] = useState(0)
  const [totalItemsCount, setTotalItemsCount] = useState(0)

  const replicaItems = useRef<Array<ProductItemModel>>([])
  const selectedSellerFilterRef = useRef<SellerFiltersType | undefined>(undefined)

  const { fetch: fetchItems, isLoading } = useFetch(
    getWardrobeItems,
    transformWardrobeItemsResponse,
  )

  const fetchMoreItems = useCallback(
    async (filter?: SellerFiltersType) => {
      const hasFilterChanged = selectedSellerFilter !== filter

      if (!hasFilterChanged && (endReached || isLoading)) return

      const isAllItemsFilterSelected = !filter

      if (hasFilterChanged) {
        setItems([])
        replicaItems.current = []
      }

      setSelectedSellerFilter(filter)
      selectedSellerFilterRef.current = filter

      const { transformedData } = await fetchItems({
        userId,
        filter,
        order: sortBy,
        catalogIds: catalogId ? [catalogId] : undefined,
        perPage: DEFAULT_PAGE_SIZE,
        currentPage: hasFilterChanged ? STARTING_PAGE_INDEX : currentPage,
      })

      if (selectedSellerFilterRef.current !== filter) return

      if (!transformedData) return

      const { items: newItems, pagination } = transformedData

      const { currentPage: newCurrentPage, totalPages, totalEntries } = pagination

      const resolveTotalItemsCount = () => {
        const isCatalogSelected = !!catalogId

        if (isCatalogSelected || !isAllItemsFilterSelected) return totalItemsCount

        return totalEntries
      }

      const newTotalItemsCount = resolveTotalItemsCount()

      replicaItems.current = uniqBy(
        [...replicaItems.current, ...filterReplicaItems(newItems)],
        'id',
      )
      setItemsCount(totalEntries)
      setTotalItemsCount(newTotalItemsCount)
      setItems(prevItems => uniqBy([...replicaItems.current, ...prevItems, ...newItems], 'id'))
      setCurrentPage(newCurrentPage)
      setEndReached(newCurrentPage === totalPages)
    },
    [
      catalogId,
      currentPage,
      endReached,
      fetchItems,
      isLoading,
      selectedSellerFilter,
      sortBy,
      totalItemsCount,
      userId,
    ],
  )

  async function updateFilters(newSortBy: SortByOption, newCatalogId: number | null) {
    setSortBy(newSortBy || DEFAULT_SORT_BY)
    setCatalogId(newCatalogId)

    const { transformedData } = await fetchItems({
      userId,
      order: newSortBy,
      catalogIds: newCatalogId ? [newCatalogId] : null,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: STARTING_PAGE_INDEX,
    })

    if (!transformedData) return

    const { items: newItems, pagination } = transformedData
    const { currentPage: newCurrentPage, totalPages, totalEntries } = pagination

    setCurrentPage(newCurrentPage)
    setEndReached(newCurrentPage === totalPages)
    setItemsCount(totalEntries)
    setItems(newItems)
  }

  return {
    items,
    itemsCount,
    totalItemsCount,
    isLoading,
    sortBy,
    catalogId,
    selectedSellerFilter,
    fetchMoreItems,
    updateFilters,
  }
}

export default useWardrobeItems
