'use client'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'
import { useSession } from '@marketplace-web/shared/session'
import { UserDto } from '@marketplace-web/shared/user-data'

type Props = {
  user: UserDto | undefined
  abTestName: string
  shouldExposeSelf?: boolean
}

const useSellerBadgeAbTest = ({ user, abTestName, shouldExposeSelf = true }: Props) => {
  const isFastReplierBadgeEnabled = useFeatureSwitch('web_fast_replier_badge')
  const currentUser = useSession().user

  const isViewingSelf = currentUser?.id === user?.id
  const bothUsersNotBusiness = user && !currentUser?.business && !user.business

  const shouldTrackAbTest =
    bothUsersNotBusiness && isFastReplierBadgeEnabled && (shouldExposeSelf || !isViewingSelf)
  const abTest = useAbTest(abTestName)
  useTrackAbTest(abTest, shouldTrackAbTest)
  useTrackAbTest(useAbTest(`${abTestName}_aa_test`), shouldTrackAbTest)

  return abTest
}

export default useSellerBadgeAbTest
