import { compact } from 'lodash'

import { UserDto } from '@marketplace-web/shared/user-data'

import { USER_STATUS_MEMBER_DELETED } from '../constants'
import useFetchProfileUserInfo from './useFetchUserInfo'

export const useGetHasUserStatuses = (user: UserDto) => {
  const { userInfo } = useFetchProfileUserInfo({ userId: user.id })

  const {
    fundraiser,
    moderator,
    volunteer_moderator,
    is_identity,
    is_god,
    is_hated,
    account_status,
    is_account_banned,
  } = user

  const {
    showSupportFunctionality,
    isTemporarilyBanned,
    isPermanentlyBanned,
    isCompromised,
    isShadowBanned,
  } = userInfo

  const isAgent = showSupportFunctionality || is_god

  const donationStatus = fundraiser?.active
  const productTeamStatus = moderator
  const volunteerStatus = volunteer_moderator
  const identityStatus = is_identity
  const bannedStatusForUsers = is_account_banned && !is_hated && !isAgent
  const bannedStatusForAgents = isTemporarilyBanned && isPermanentlyBanned
  const lockedStatus = isCompromised
  const blockedStatus = is_hated
  const shadowBannedStatus = isShadowBanned
  const deletedStatus = account_status === USER_STATUS_MEMBER_DELETED

  const statuses = compact([
    donationStatus,
    productTeamStatus,
    volunteerStatus,
    identityStatus,
    bannedStatusForUsers,
    bannedStatusForAgents,
    lockedStatus,
    blockedStatus,
    shadowBannedStatus,
    deletedStatus,
  ])

  return Boolean(statuses.length)
}
