export enum PromotionStorageKeys {
  ShowBumped = 'pushed_up_promotion',
  ShowFeedback = 'feedback_promotion',
  ShowUploadAnotherItemTip = 'upload_another_item_tip_promotion',
}

export enum ItemAfterUploadActions {
  ShowUploadAnotherItemTip = 'show_upload_another_item_tip',
  ShowEVSModal = 'show_electronics_verification_modal',
  ShowIVSModal = 'show_item_verification_modal',
  ShowBumpsCheckout = 'show_bumps_checkout',
}
