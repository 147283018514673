'use client'

import { Button } from '@vinted/web-ui'

import { PerformanceChartButton, ProfileItemBumpButton } from '@marketplace-web/domain/vas'
import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { Grid as ItemGrid } from '@marketplace-web/shared/ui-helpers'

import { impressionEvent } from '_libs/common/event-tracker/events'
import { TrackedProductItem } from 'components/ProductItem'
import { ItemAlertStatus } from 'constants/item'
import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { EDIT_ITEM_URL, ITEM_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import { getProductItemStatus } from 'data/utils/item'
import { ProductItemModel } from 'types/models/product-item'

import { logClosetItemError } from '../../../../utils/log'

type Props = {
  items: Array<ProductItemModel>
  userId: number
  allowBump: boolean
  isViewingSelf: boolean
}

const ItemsContainer = ({ items, userId, allowBump, isViewingSelf }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('item')
  const breakpoints = useBreakpoint()

  const profileReworkAbTest = useAbTest('profile_wardrobe_web_rework')
  const isProfileReworkTestOn = profileReworkAbTest?.variant === 'on' && !breakpoints.portables

  const handleItemVisibility: ComponentProps<
    typeof ItemGrid<ProductItemModel>
  >['onItemVisible'] = ({ item: { data, id }, index }) => {
    const event = impressionEvent({
      id,
      position: index + 1,
      contentType: ListItemContentType.Item,
      contentSource: ContentSource.UserItems,
      itemOwnerId: data.user.id || userId,
    })

    track(event)
  }

  const handleErrorLogging = (err: Error | null) => logClosetItemError(err)

  const renderItemFooter = (item: ProductItemModel) => {
    const { id } = item

    if (!isViewingSelf) return null

    const status = getProductItemStatus(item, isViewingSelf)

    switch (status) {
      case ItemAlertStatus.ReplicaProof:
      case ItemAlertStatus.UnderReview:
        return (
          <Button
            text={translate('wardrobe.actions.edit')}
            theme="primary"
            size={Button.Size.Small}
            url={EDIT_ITEM_URL(id)}
          />
        )

      case 'bumped': {
        return <PerformanceChartButton itemId={id} />
      }

      case 'draft': {
        return (
          <Button
            text={translate('drafts.finish_editing')}
            theme="primary"
            size={Button.Size.Small}
            url={EDIT_ITEM_URL(id)}
          />
        )
      }

      default: {
        if (!allowBump) return null

        return <ProfileItemBumpButton item={item} />
      }
    }
  }

  const renderItem: ComponentProps<typeof ItemGrid<ProductItemModel>>['renderItem'] = (
    { data },
    index,
  ) => (
    <TrackedProductItem
      item={data}
      renderFooter={renderItemFooter(data)}
      showStatus
      viewingSelf={isViewingSelf}
      tracking={{
        id: data.id,
        contentType: ListItemContentType.Item,
        contentSource: ContentSource.UserItems,
        position: index + 1,
        screen: isViewingSelf ? Screen.CurrentUserProfile : Screen.UserProfile,
      }}
      url={data.isDraft ? EDIT_ITEM_URL(data.id) : ITEM_URL(data.id)}
      hideBusinessBadge
      isBumpTextHidden
    />
  )

  return (
    <ItemGrid
      items={items.map(item => ({ id: item.id, data: item }))}
      renderItem={renderItem}
      onItemVisible={handleItemVisibility}
      widthResolver={() => (isProfileReworkTestOn ? 'one-fourth' : 'one-fifth')}
      preventLog
      handleErrorLogging={handleErrorLogging}
    />
  )
}

export default ItemsContainer
