'use client'

import { Button, Dialog, Text } from '@vinted/web-ui'

import { NOTICE_AND_ACTION_FORM, SIGNUP_URL } from 'constants/routes'
import { useTranslate } from '@marketplace-web/shared/i18n'

type Props = {
  isVisible: boolean
  onClose: () => void
}

const UnauthenticatedReportModal = ({ isVisible, onClose }: Props) => {
  const translate = useTranslate('user.report_modal')

  const getNtdFormLink = () => (
    <a href={NOTICE_AND_ACTION_FORM} rel="noreferrer">
      {translate('reporting_platform_link')}
    </a>
  )

  return (
    <Dialog
      show={isVisible}
      closeOnOverlay
      defaultCallback={onClose}
      title={translate('title')}
      body={
        <Text
          as="span"
          text={translate('body', { reporting_platform_link: getNtdFormLink() })}
          html
        />
      }
      testId="report-user-modal"
      actions={[
        {
          text: translate('actions.login'),
          url: SIGNUP_URL,
          style: Button.Styling.Filled,
        },
      ]}
    />
  )
}

export default UnauthenticatedReportModal
