'use client'

import {
  Attention16,
  Block16,
  CheckCircle16,
  CheckmarkShield16,
  Lock16,
  ShieldFilled16,
  X16,
} from '@vinted/monochrome-icons'
import { Button, Cell, Icon, Spacer, Text } from '@vinted/web-ui'
import { compact } from 'lodash'
import { Fragment } from 'react'

import { FundraiserInfo } from '@marketplace-web/domain/fundraisers'
import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'

import { MEMBER_RESTORE_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import { UserDto } from '@marketplace-web/shared/user-data'

import { USER_STATUS_MEMBER_DELETED } from '../../../constants'
import { getIsAccountBanPermanent } from '../../../utils/user'
import UserInfoBlock from './UserInfoBlock'

type Props = {
  user: UserDto
  screen: Screen
  showSupportFunctionality: boolean
  isShadowBanned?: boolean
  isPermanentlyBanned?: boolean
  isTemporarilyBanned?: boolean
  isCompromised?: boolean
  isListingRestricted?: boolean
  viewingSelf: boolean
  onUnblockClick?: () => void
}

const UserStatuses = ({
  user,
  screen,
  showSupportFunctionality,
  isShadowBanned,
  isPermanentlyBanned,
  isTemporarilyBanned,
  isCompromised,
  isListingRestricted,
  viewingSelf,
  onUnblockClick,
}: Props) => {
  const translate = useTranslate('user')
  const breakpoints = useBreakpoint()

  const profileReworkAbTest = useAbTest('profile_wardrobe_web_rework')
  const isProfileReworkTestOn = profileReworkAbTest?.variant === 'on' && !breakpoints.portables

  const {
    fundraiser,
    moderator,
    volunteer_moderator,
    is_identity,
    is_god,
    is_hated,
    account_ban_date,
    account_status,
    id,
    is_account_banned,
    listing_restricted_until,
  } = user

  const isAgent = showSupportFunctionality || is_god

  function renderDonationStatus() {
    if (!fundraiser?.active) return null

    return <FundraiserInfo user={user} screen={screen} styling={Cell.Styling.Tight} />
  }

  function renderProductTeamStatus() {
    if (!moderator) return null

    return (
      <UserInfoBlock iconName={CheckmarkShield16} iconColor={Icon.Color.Primary}>
        {translate('status.product_team')}
      </UserInfoBlock>
    )
  }

  function renderVolunteerStatus() {
    if (!volunteer_moderator) return null

    return (
      <UserInfoBlock iconName={ShieldFilled16} iconColor={Icon.Color.Primary}>
        {translate('status.volunteer_moderator')}
      </UserInfoBlock>
    )
  }

  function renderIdentityStatus() {
    if (!is_identity) return null

    return (
      <UserInfoBlock iconName={CheckCircle16} iconColor={Icon.Color.Success}>
        {translate('status.identity_confirmed')}
      </UserInfoBlock>
    )
  }

  function renderBannedStatusForUsers() {
    if (!is_account_banned || is_hated || isAgent) return null

    const isAccountBanPermanent = getIsAccountBanPermanent(account_ban_date)

    let bannedMessage = translate('admin.messages.banned_no_date')

    if (viewingSelf && !isAccountBanPermanent && account_ban_date) {
      bannedMessage = translate('admin.messages.banned', {
        date: account_ban_date,
      })
    }

    return (
      <UserInfoBlock iconName={Block16} iconColor={Icon.Color.Warning}>
        <Text as="span" theme="warning">
          {bannedMessage}
        </Text>
      </UserInfoBlock>
    )
  }

  function renderBannedStatusForAgents() {
    if (!isTemporarilyBanned && !isPermanentlyBanned) return null

    let bannedMessage = translate('admin.messages.banned_no_date')

    if (isTemporarilyBanned && account_ban_date) {
      bannedMessage = translate('admin.messages.banned', {
        date: account_ban_date,
      })
    }

    return (
      <UserInfoBlock iconName={Block16} iconColor={Icon.Color.Warning}>
        <Text as="span" theme="warning">
          {bannedMessage}
        </Text>
      </UserInfoBlock>
    )
  }

  function renderItemRestrictionStatusForAgents() {
    if (!isListingRestricted || !listing_restricted_until) return null

    return (
      <UserInfoBlock iconName={Attention16} iconColor={Icon.Color.Warning}>
        <Text as="span" theme="warning">
          {translate('admin.messages.listing_restricted', { date: listing_restricted_until })}
        </Text>
      </UserInfoBlock>
    )
  }

  function renderLockedStatus() {
    if (!isCompromised) return null

    return (
      <UserInfoBlock iconName={Lock16} iconColor={Icon.Color.Warning}>
        <Text as="span" theme="warning">
          {translate('admin.messages.locked')}
        </Text>
      </UserInfoBlock>
    )
  }

  function renderBlockedStatus() {
    if (!is_hated) return null

    return (
      <>
        <UserInfoBlock iconName={Block16} iconColor={Icon.Color.Warning}>
          <Text as="span" theme="warning">
            {translate('admin.messages.blocked')}
          </Text>
        </UserInfoBlock>
        {onUnblockClick && (
          <>
            <Spacer size={Spacer.Size.Large} />
            <Button
              testId="unblock-user-button"
              inline
              text={translate('action.unblock')}
              styling={Button.Styling.Filled}
              onClick={onUnblockClick}
            />
          </>
        )}
      </>
    )
  }

  function renderShadowBannedStatus() {
    if (!isShadowBanned) return null

    return (
      <UserInfoBlock iconName={Block16} iconColor={Icon.Color.Warning}>
        <Text as="span" theme="warning">
          {translate('admin.messages.on_hold_no_date')}
        </Text>
      </UserInfoBlock>
    )
  }

  function renderDeletedStatus() {
    if (account_status !== USER_STATUS_MEMBER_DELETED) return null

    return (
      <UserInfoBlock iconName={X16} iconColor={Icon.Color.Warning}>
        <Text as="span" theme="warning">
          {translate('admin.messages.deleted', {
            'member-restore-url': chunks => (
              <a key="member-restore-url" href={MEMBER_RESTORE_URL(id)}>
                {chunks}
              </a>
            ),
          })}
        </Text>
      </UserInfoBlock>
    )
  }

  const statuses = compact([
    renderDonationStatus(),
    renderProductTeamStatus(),
    renderVolunteerStatus(),
    renderIdentityStatus(),
    renderBannedStatusForUsers(),
    renderBannedStatusForAgents(),
    renderItemRestrictionStatusForAgents(),
    renderLockedStatus(),
    renderBlockedStatus(),
    renderShadowBannedStatus(),
    renderDeletedStatus(),
  ])

  if (!statuses.length) return null

  return (
    <Cell
      styling={isProfileReworkTestOn && viewingSelf ? Cell.Styling.Tight : Cell.Styling.Default}
    >
      <SeparatedList separator={<Spacer size={Spacer.Size.Small} />}>
        {statuses.map((status, index) => (
          <Fragment key={index}>{status}</Fragment>
        ))}
      </SeparatedList>
    </Cell>
  )
}

export default UserStatuses
