'use client'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { useTracking } from '@marketplace-web/shared/event-tracker'

import { MEMBER_PROFILE_URL } from 'constants/routes'
import { profileClickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = {
  ownerItemCount: number
  ownerId: number
  itemId: string
}

const MAX_OWNER_ITEMS_PER_PAGE = 24

const ViewAllUserItemsLink = ({ ownerItemCount, ownerId, itemId }: Props) => {
  const translate = useTranslate('item')
  const { track } = useTracking()

  const handleOnClick = () => {
    track(
      profileClickEvent({
        target: ClickableElement.ViewAllUserItems,
        ownerId: ownerId.toString(),
        itemId,
      }),
    )
  }

  if (ownerItemCount < MAX_OWNER_ITEMS_PER_PAGE) return null

  return (
    <div className="u-ui-margin-vertical-x-large u-text-center">
      <a
        href={MEMBER_PROFILE_URL(ownerId)}
        onClick={handleOnClick}
        data-testid="view-all-user-items-link"
      >
        {translate('view_all_owner_items', { count: ownerItemCount })}
      </a>
    </div>
  )
}

export default ViewAllUserItemsLink
