'use client'

import { unescape } from 'lodash'
import { MouseEvent, useState } from 'react'
import { useIntl } from 'react-intl'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { isInternalUrl } from '@marketplace-web/shared/utils'

import { clickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { useSystemConfiguration } from '@marketplace-web/shared/system-configuration'

import LeavingVintedModal from './LeavingVintedModal'
import { linkifyContent } from './utils'

const TRANSLATION_KEY = 'linkified-message'

const renderHashtag = (href: string, text: string, index?: number) => (
  <a key={`${href}-${index}`} href={href}>
    {text}
  </a>
)

type Props = {
  text: string
  trackingData?: Record<string, string | number | null | boolean>
}

const LinkifiedMessage = ({ text, trackingData }: Props) => {
  const [url, setUrl] = useState<string>()
  const { messages, formatMessage } = useIntl()
  const { track } = useTracking()

  const urlRegexConfig = useSystemConfiguration()?.urlRegex

  const urlRegex = urlRegexConfig ? new RegExp(urlRegexConfig, 'g') : undefined

  // TODO: remove this check or make TS typings more accurate
  if (!text) return null

  // Workaround for race condition when event haven't finished and react clears url which leads to navigation to undefined link
  const handleClose = () => setTimeout(() => setUrl(undefined), 0)

  const eventTargetDetails = {
    url,
    ...trackingData,
  }

  const handleLinkClick = (event: MouseEvent, link: string) => {
    if (isInternalUrl(link)) return

    track(
      clickEvent({
        target: ClickableElement.ShowLeavingModal,
        targetDetails: JSON.stringify({ ...eventTargetDetails, url: link }),
      }),
    )

    event.preventDefault()
    setUrl(link)
  }

  const renderUrl = (href: string, formattedText: string) => (
    <a
      key={Math.random()}
      href={href}
      target="_blank"
      rel="noreferrer"
      onClick={event => handleLinkClick(event, href)}
    >
      {formattedText}
    </a>
  )

  const linkifiedContent = linkifyContent(
    unescape(text),
    {
      url: renderUrl,
      hashtag: renderHashtag,
    },
    urlRegex,
  )

  messages[TRANSLATION_KEY] = linkifiedContent.text

  return (
    <>
      <span>{formatMessage({ id: TRANSLATION_KEY }, linkifiedContent.values)}</span>
      <LeavingVintedModal
        url={url}
        eventTargetDetails={JSON.stringify(eventTargetDetails)}
        onClose={handleClose}
      />
    </>
  )
}

export default LinkifiedMessage
