'use client'

import { ReactNode, useCallback, useMemo, useState } from 'react'

import { UPLOADED_ITEM_ID, UPLOAD_SESSION_ID } from '@marketplace-web/domain/item-upload'
import { isResponseError } from '@marketplace-web/shared/api-client'
import { getLocalStorageItem } from '@marketplace-web/shared/browser'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'

import { getShowItemUploadFeedback } from 'data/api'

import PromoAfterItemUploadContext, {
  PromoAfterItemUpload,
  PromotionsType,
} from './PromoAfterItemUploadContext'
import { getPromotionsFromLocalStorage } from '../utils/getPromotionsFromLocalStorage'
import usePromoShown from '../hooks/usePromoShown'

type Props = {
  children: ReactNode
}

const PromoAfterItemUploadProvider = ({ children }: Props) => {
  const [currentPromotion, setCurrentPromotion] = useState<PromoAfterItemUpload | null>(null)
  const isFeedbackEnabled = useFeatureSwitch('item_upload_feedback_form')

  usePromoShown(currentPromotion)

  const itemIdFromStorage = getLocalStorageItem(UPLOADED_ITEM_ID)
  const uploadedItemId = parseInt(itemIdFromStorage || '', 10)
  const uploadSessionId = getLocalStorageItem(UPLOAD_SESSION_ID)

  const promotions: PromotionsType = useMemo(() => getPromotionsFromLocalStorage(), [])

  const setNextPromotion = useCallback(async () => {
    switch (currentPromotion) {
      case null:
        if (uploadedItemId && promotions.bumped) {
          setCurrentPromotion(PromoAfterItemUpload.BumpCheckout)
          break
        }
      // falls through
      case PromoAfterItemUpload.BumpCheckout:
        if (uploadedItemId && promotions.uploadAnotherItemTip) {
          setCurrentPromotion(PromoAfterItemUpload.ListAnotherItem)
          break
        }
      // falls through
      case PromoAfterItemUpload.ListAnotherItem:
        if (uploadedItemId && isFeedbackEnabled && promotions.feedback) {
          const response = await getShowItemUploadFeedback()

          if (!isResponseError(response) && response.show_feedback) {
            setCurrentPromotion(PromoAfterItemUpload.ItemUploadFeedback)
            break
          }
        }
      // falls through
      default:
        setCurrentPromotion(PromoAfterItemUpload.Empty)
    }
  }, [
    currentPromotion,
    isFeedbackEnabled,
    promotions.bumped,
    promotions.feedback,
    promotions.uploadAnotherItemTip,
    uploadedItemId,
  ])

  const value = useMemo(() => {
    return {
      uploadedItemId,
      uploadSessionId,
      currentPromotion,
      setNextPromotion,
      promotions,
      setCurrentPromotion,
    }
  }, [uploadedItemId, uploadSessionId, currentPromotion, promotions, setNextPromotion])

  return (
    <PromoAfterItemUploadContext.Provider value={value}>
      {children}
    </PromoAfterItemUploadContext.Provider>
  )
}

export default PromoAfterItemUploadProvider
