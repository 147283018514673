'use client'

import { Button, Icon } from '@vinted/web-ui'
import { Stats16 } from '@vinted/monochrome-icons'

import { useTracking } from '@marketplace-web/shared/event-tracker'

import { clickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { ITEM_URL } from 'constants/routes'

type Props = {
  itemId: number
}

const PerformanceChartButton = ({ itemId }: Props) => {
  const { track } = useTracking()

  const handleOnClick = () => {
    track(
      clickEvent({
        target: ClickableElement.ShowItemPerformance,
        targetDetails: itemId.toString(),
        screen: Screen.CurrentUserProfile,
      }),
    )
  }

  return (
    <Button
      icon={<Icon name={Stats16} color="parent" />}
      theme="primary"
      testId="bump-performance-button"
      size={Button.Size.Small}
      url={ITEM_URL(itemId)}
      onClick={handleOnClick}
    />
  )
}

export default PerformanceChartButton
