'use client'

import { createRef, Component, ReactNode } from 'react'

type Props = {
  onOutsideClick: (event: MouseEvent) => void
  isDisabled: boolean
  children: ReactNode
  className?: string
}

class OutsideClick extends Component<Props> {
  static defaultProps = {
    isDisabled: false,
  }

  listenersAttached = false

  wrapperRef = createRef<HTMLDivElement>()

  componentDidMount() {
    const { isDisabled } = this.props

    if (isDisabled) return

    this.attachListener()
    this.listenersAttached = true
  }

  componentDidUpdate(prevProps: Props) {
    const { isDisabled } = this.props

    if (prevProps.isDisabled === isDisabled || this.listenersAttached) return

    this.attachListener()
    this.listenersAttached = true
  }

  componentWillUnmount() {
    this.removeListener()
  }

  attachListener() {
    document.addEventListener('mousedown', this.onMouseDown)
  }

  removeListener() {
    document.removeEventListener('mousedown', this.onMouseDown)
  }

  onMouseDown = (event: MouseEvent) => {
    const { current: node } = this.wrapperRef
    const { isDisabled, onOutsideClick } = this.props

    if (!node || isDisabled) return
    if (event.target instanceof Node && node.contains(event.target)) return

    onOutsideClick(event)
  }

  render() {
    const { children, className } = this.props

    return (
      <div ref={this.wrapperRef} className={className}>
        {children}
      </div>
    )
  }
}

export default OutsideClick
