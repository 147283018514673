import { Response } from '@marketplace-web/shared/api-client'

import { GetBumpsNewMemberItemUploadMotivationResp } from 'types/api/response/get-bumps-new-member-item-upload-motivation-resp'

export const transformBumpsNewMemberItemUploadMotivationResponse = (
  response: Response<GetBumpsNewMemberItemUploadMotivationResp>,
) => ({
  title: response.title,
  subtitle: response.subtitle,
  expirationWarning: response.expiration_warning,
  ctaTitle: response.cta_title,
})
